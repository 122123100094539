import {Component, ElementRef, EventEmitter, Input, OnInit} from '@angular/core';
import {AbstractHistoryGraph, BackgroundLegend} from '../../../../shared/components/history-graph/abstract-history-graph/abstract-history-graph';
import Annotation = dygraphs.Annotation;

interface SimpleGraphDataStructure {
    data: Array<Array<Date | number | null>>;
    events: Annotation[];
}


@Component({
    selector: 'app-simple-graph',
    templateUrl: './simple-graph.component.html',
    styleUrls: ['./simple-graph.component.scss']
})
export class SimpleGraphComponent extends AbstractHistoryGraph<SimpleGraphDataStructure> implements OnInit {
    public autoReload = false;
    public autoReloadChange = new EventEmitter<boolean>();

    @Input()
    public graphData!: Array<Array<Date | number | null>>;

    @Input()
    public labels!: string[];
    @Input()
    private stepPlot: boolean = false;

    public constructor(
        private readonly element: ElementRef
    ) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        if(!this.labels) {
            this.labels = this.graphData[0].map((v, idx) => idx + "");
        }
        await this.init(this.element);
    }

    public defaultStart(): number {
        return this.graphData?.reduce((min, line) => Math.min(min, line[0] as number), Number.MAX_VALUE) || 0
    }

    public defaultEnd(): number {
        return this.graphData?.reduce((max, line) => Math.max(max, line[0] as number), Number.MIN_VALUE) || 0
    }

    public getBackgroundLegend(): BackgroundLegend[] {
        return [];
    }

    protected async getConfig(): Promise<dygraphs.Options> {
        return Promise.resolve({
            labels: this.labels,
            stepPlot: this.stepPlot,
        });
    }

    protected async getMaxY2(): Promise<number> {
        return Promise.resolve(0);
    }

    protected async loadData(start: number, end: number): Promise<SimpleGraphDataStructure> {
        const events: Annotation[] = [];
        return Promise.resolve({
            data: this.graphData,
            events
        });
    }

}
