import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {FleetService} from "../../../vehicle/service/fleet.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {FleetFlexCreateDerSystemArgs, FleetFlexInfo, MeterHistory} from "@io-elon-common/frontend-api";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogType} from "../../../../shared/components/help-box/dialogType";
import {ToastrService} from "ngx-toastr";
import {deepEqual} from "../../../../shared/helper/util-functions";
import {MeterService} from "../../../meter/service/meter.service";

const GRAPH_LEGEND_SCHEDULE_FLEX_METER = ["Zeit", "Plan von Flexibilitätsmarkt", "Davon nicht gehandelte Leistung", "Umgesetzte Leistung"];
const GRAPH_LEGEND_FLEX_METER = ["Zeit", "Nicht gehandelte Leistung", "Umgesetzte Leistung"];
const GRAPH_LEGEND_SCHEDULE_FLEX = ["Zeit", "Plan von Flexibilitätsmarkt", "Davon nicht gehandelte Leistung"];
const GRAPH_LEGEND_FLEX = ["Zeit", "Nicht gehandelte Leistung"];
const GRAPH_LEGEND_NONE: [] = [];
const MAX_DATE = new Date(8640000000000000);

interface GraphInput {
    count: number,
    getTst(idx: number): Date
    getVal(idx: number, lastVal: number[]): number;
}


@Component({
  selector: 'app-flex-view',
  standalone: false,
  templateUrl: './flex-view.component.html',
  styleUrl: './flex-view.component.scss'
})
export class FlexViewComponent implements OnInit, OnDestroy {
    public readonly DialogType = DialogType;
    public readonly StateEnum = FleetFlexInfo.StateEnum;
    private fleetSubscription?: Subscription;
    private selectedFleetId?: number;
    public showGraph: boolean = true;
    public createDerSystemArgs: FleetFlexCreateDerSystemArgs = {
        city: "",
        zip: "",
        country: "Germany",
        street: "",
        zoneId: ""
    };

    public flexInfo: BehaviorSubject<FleetFlexInfo | undefined> = new BehaviorSubject<FleetFlexInfo | undefined>(undefined);
    private popup?: MatDialogRef<any>;

    private lazyGraphData: {
        flex?: FleetFlexInfo,
        meterHistory?: MeterHistory,
        data: Array<Array<Date | number | null>>
    } = { data: [] };
    private meterHistory?: MeterHistory;



    public constructor(
        private readonly fleetService: FleetService,
        private readonly dialog: MatDialog,
        private readonly toastr: ToastrService,
        private readonly meterService: MeterService,
    ) {

    }

    ngOnInit(): void {
        this.fleetSubscription = this.fleetService.selectedFleet.subscribe(f => {
            if(typeof f === "number") {
                this.flexInfo = this.fleetService.getFlexInfo(f);
                this.selectedFleetId = f;

                this.flexInfo.subscribe(async flexInfo => {
                    if(flexInfo && flexInfo.flex) {
                        await this.updateMeterHistory(new Date(flexInfo.flex.tstBasePwrStart), new Date(flexInfo.flex.tstEnd));
                    } else {
                        this.meterHistory = undefined;
                    }
                })
            }
        });
    }

    ngOnDestroy(): void {
        this.fleetSubscription?.unsubscribe();
    }

    private async updateMeterHistory(start: Date, end: Date) {
        const meters = await this.meterService.getAllPromise()
        const meter = meters.find(m => m.specialUseCase === ("flexTrading." + this.fleetService.selectedFleet.getValue()))
        if(!meter) {
            return null;
        }
        this.meterHistory = await this.meterService.getHistory(meter.id, start, end);
    }

    get graphData(): Array<Array<Date | number | null>> {
        const flexInfo = this.flexInfo.getValue();
        if(deepEqual(flexInfo, this.lazyGraphData.flex) && this.meterHistory === this.lazyGraphData.meterHistory) {
            return this.lazyGraphData.data;
        }

        const result: Array<Array<Date | number | null>> = []
        if(flexInfo) {
            const flex = flexInfo.flex;
            const schedule = flexInfo.schedule;

            const defaults: number[] = [];
            if(flex) { defaults.push(0); }
            if(schedule) { defaults.push(0); }
            if(this.meterHistory) { defaults.push(0); }

            if(flex) {
                result.push([new Date(flex.tstBasePwrStart-1), ...defaults]);

                const scheduleLineDef: GraphInput[] = schedule ? [{
                    count: schedule.ptus.length,
                    getTst: idx => new Date(schedule.ptus[idx].tst),
                    getVal: (idx, lastVal) => schedule.ptus[idx].power / 1000 + lastVal[1]
                }] : [];

                const meterHistoryLineDef: GraphInput[] = this.meterHistory ? [{
                    count: this.meterHistory.data.p1!.length,
                    getTst: idx => new Date(this.meterHistory!.data.p1![idx].time),
                    // Formula Meter, so P1-P3 is set, PSum is missing :(
                    getVal: idx => {
                        if(Date.now() > this.meterHistory!.data.p1![idx].time) {
                            return NaN;
                        }
                        return (this.meterHistory!.data.p1![idx].val + this.meterHistory!.data.p2![idx].val + this.meterHistory!.data.p3![idx].val) / 1000
                    }
                }] : [];

                result.push(...this.buildGraph([
                    ...scheduleLineDef,
                    {
                        count: flex.basePwr.length,
                        getTst: idx => new Date(flex.tstBasePwrStart + (flex.basePwrSlotDuration * idx)),
                        getVal: idx => flex.basePwr[idx] / 1000
                    },
                    ...meterHistoryLineDef,
                ], new Date(flex.tstStart), new Date(flex.tstEnd)));

                result.push([new Date(flex.tstEnd), ...defaults]);
            }
        }

        this.lazyGraphData = {
            flex: flexInfo,
            data: result,
            meterHistory: this.meterHistory
        }
        this.showGraph = false;
        setTimeout(() => this.showGraph = true, 100);
        return result;
    }

    private buildGraph(lines: GraphInput[], start: Date, end: Date): Array<Array<Date | number | null>> {
        const idx: number[] = lines.map(() => 0);
        const lastVal: number[] = lines.map(() => 0);
        const count: number[] = lines.map(l => l.count);

        const result: Array<Array<Date | number | null>> = [];
        while(idx.some((i, colIdx)=> i < count[colIdx])) {
            const tst = lines.map((l, i) => {
                if(idx[i] >= count[i]) {
                    return MAX_DATE;
                }
                return l.getTst(idx[i])
            });

            const min = tst.reduce((a, b) => a < b ? a : b);

            tst.forEach((t, colIdx) => {
                if(t === min) {
                    lastVal[colIdx] = lines[colIdx].getVal(idx[colIdx], lastVal);
                    idx[colIdx]++;
                }
            });

            if(min < start) {
                continue;
            }
            if(min > end) {
                break;
            }
            result.push([min, ...lastVal]);
        }

        return result;
    }


    get graphLabels(): string[] {
        const flexInfo = this.flexInfo.getValue();
        if(flexInfo) {
            const flex = flexInfo.flex;
            const schedule = flexInfo.schedule;

            if(flex && schedule && this.meterHistory) {
                return GRAPH_LEGEND_SCHEDULE_FLEX_METER;
            } else if(flex && schedule) {
                return GRAPH_LEGEND_SCHEDULE_FLEX;
            } else if(flex && this.meterHistory) {
                return GRAPH_LEGEND_FLEX_METER;
            } else if(flex) {
                return GRAPH_LEGEND_FLEX;
            } else {
                return GRAPH_LEGEND_NONE;
            }
        }
        return GRAPH_LEGEND_NONE;
    }


    showPopup(createDerSystemInputs: TemplateRef<any>) {
        this.popup = this.dialog.open(createDerSystemInputs);
    }


    private validateDerSystem(): boolean {
        return !!this.createDerSystemArgs.zip &&
            !!this.createDerSystemArgs.city &&
            !!this.createDerSystemArgs.zip &&
            !!this.createDerSystemArgs.country &&
            !!this.createDerSystemArgs.street &&
            !!this.createDerSystemArgs.zoneId;

    }

    async createDerSystem() {
        if(this.selectedFleetId !== undefined) {
            if(!this.validateDerSystem()) {
                this.toastr.warning("Bitte alle Felder ausfüllen")
                return;
            }
            await this.fleetService.createDerSystem(this.selectedFleetId, this.createDerSystemArgs);
            if(this.popup) {
                this.popup.close();
            }
        } else {
            this.toastr.warning("Keine Flotte ausgewählt.")
        }
    }
}
