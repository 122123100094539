<div style="position: relative">
    <mat-spinner *ngIf="reloadExecutor.running || refreshActive" class="center-spinner"></mat-spinner>
    <table mat-table [dataSource]="dataSource" class="full-width-table" appDragHelper (appOnDragX)="onDragX($event)"
           appZoomHelper (appOnZoom)="onZoom($event)">
        <!-- Fahrzeug -->
        <ng-container matColumnDef="icon">
            <th mat-header-cell *matHeaderCellDef>Ladepunkt</th>
            <td mat-cell *matCellDef="let element" class="col-icon">
                <div class="evse-icon-container">
                    <mat-icon class="iblack" svgIcon="{{getHealthIcon(element)}}"
                              [class]="element.liveData.healthStatus != undefined? 'evse-icon' : ''"></mat-icon>
                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef class="col-icon"></td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="col-name">
                <app-evse-link [evse]="element"></app-evse-link>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Charging sessions -->
        <ng-container matColumnDef="graph">
            <th mat-header-cell *matHeaderCellDef class="graph" id="graphHeader">
                <div *ngFor="let style of getDayLinePositions()" [style]="style" class="grid">
                </div>
                <div *ngFor="let header of getHeaderTimes()" [style]="header.style" class="headerTime">
                    <span class="strong">{{ header.line1 }}</span><br>{{ header.line2 }}
                </div>
            </th>
            <td mat-cell *matCellDef="let element" class="graph">
                <div *ngFor="let style of getWeekends()" [style]="style" class="weekend">
                </div>
                <div *ngFor="let style of getDayLinePositions()" [style]="style" class="grid">
                </div>
                <div
                    cdk-overlay-origin #trigger="cdkOverlayOrigin" (mouseenter)="isOpen = entry"
                    (mouseleave)="isOpen = undefined"
                    *ngFor="let entry of getEvseHealthStatusEntries(element)"
                    [style]="calcStyle(entry)"
                    [class]="getCssClass(entry)"
                >
                    <ng-template cdk-connected-overlay
                                 [cdkConnectedOverlayOpen]="isOpen == entry"
                                 [cdkConnectedOverlayOrigin]="trigger"
                                 [cdkConnectedOverlayPositions]="[
                                    {
                                        originX: 'center',
                                        originY: 'bottom',
                                        overlayX: 'center',
                                        overlayY: 'top'
                                    }
                                 ]"
                    >
                        <div class="megatooltip">
                            <h4>{{ this.evseHealthDataUtils.getHealthStatusText(entry.healthStatus) }}</h4>
                            <h5 *ngIf="entry.note">{{entry.note}}</h5>
                            {{getTooltip(entry)}}
                        </div>
                    </ng-template>
                </div>
                <div [style]="getNow()" class="now-line">
                </div>

                <div *ngIf="getHighlight(element).visible" [style]="getHighlight(element).style" class="highlight">
                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <div class="legends">
                    <div class="legend">
                        <div class="legend-box broken"></div>
                        <span class="legend-text">Störung</span>
                    </div>
                </div>
                <div class="legends">
                    <div class="legend">
                        <div class="legend-box on-repair"></div>
                        <span class="legend-text">Störung(In Arbeit)</span>
                    </div>
                </div>
                <div class="legends">
                    <div class="legend">
                        <div class="legend-box maintenance"></div>
                        <span class="legend-text">Wartung</span>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
    <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="10"
                   [pageSizeOptions]="[10, 25, 50, 100, 250]" showFirstLastButtons>
    </mat-paginator>
</div>
